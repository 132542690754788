export class AiProfile {
  id = null
  userId = null
  companyTypes = []
  communicationTones = []
  targetAudiences = []
  communicationObjectives = []
  sectors = []
  writingStyles = []
  businessModels = []
  companyValues = []
  communicationFrequencies = []
  companyObjectives = []
  customerBehaviors = []
  communicationChannels = []
  brandImages = []
  missionsVisions = []
  interestInfo = ''

  loadData (data) {
    this.id = data.id
    this.userId = data.user_id
    this.companyTypes = data.company_types
    this.communicationTones = data.communication_tones
    this.targetAudiences = data.target_audiences
    this.communicationObjectives = data.communication_objectives
    this.sectors = data.sectors
    this.writingStyles = data.writing_styles
    this.businessModels = data.business_models
    this.companyValues = data.company_values
    this.communicationFrequencies = data.communication_frequencies
    this.companyObjectives = data.company_objectives
    this.customerBehaviors = data.customer_behaviors
    this.communicationChannels = data.communication_channels
    this.brandImages = data.brand_images
    this.missionsVisions = data.missions_visions
    this.interestInfo = data.interest_info
  }
}

