import ProfileService from '@/services/ai/profile.service'
import { AiProfile } from '@/models/AiProfile.js'
import EventBus from '@/util/EventBus.js'
import UserService from '@/services/user.service'

export default {
  name: 'BillingProfile',
  components: { },
  props: {
    aiProfileStatus: {
      required: true,
      type: Object,
    },
  },
  data () {
    return {
      aiProfile: new AiProfile(),
      listsData: {},
      loadedAiProfile: null,
    }
  },
  watch: {
    aiProfile: {
      handler: function () {
        this.aiProfileStatus.edited = this.isEdited()
      },
      deep: true,
    },
  },
  methods: {
    makeCopy () {
      this.loadedAiProfile = JSON.stringify(this.aiProfile)
    },
    isEdited () {
      return JSON.stringify(this.aiProfile) !== this.loadedAiProfile
    },
    saveProfileData () {
      ProfileService.saveProfileData(this.aiProfile).then(() => {
        EventBus.$emit('showAlert', 'success', this.$t('Perfil guardado con éxito'))
        this.makeCopy()
        this.setShowAiProfileByUser()
      })
    },
    setShowAiProfileByUser () {
      UserService.setShowAiProfileByUser({ showAiProfile: false }).then(() => {
        this.changeUserShowAiProfile(false)
      }, () => {
        EventBus.$emit('showAlert', 'danger', this.$t('Se produjo un error al guardar las preferencias').toString())
      })
    },
    changeUserShowAiProfile (showAiProfile) {
      const user = JSON.parse(localStorage.getItem('user'))
      user.showAiProfile = showAiProfile
      localStorage.setItem('user', JSON.stringify(user))
    },
  },
  created () {
    ProfileService.getProfile().then((res) => {
      this.listsData = res.listsData
      this.aiProfile.loadData(res.configData)
      this.makeCopy()
    })
  },
  mounted () {
    EventBus.$on('AiProfile:save', this.saveProfileData)
  },
}
