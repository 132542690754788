import { Service } from '@/services/service'

const PATH = 'ai/profile'

class ProfileService extends Service {
  getProfile (params) {
    return this.get(PATH, params)
  }

  saveProfileData (params) {
    return this.post(PATH + '/save', params)
  }
}

export default new ProfileService()
