<template>
  <v-container
    fluid
    class="pt-0 px-6 pb-8 custom-top-margin"
    :class="{ 'bg-primary': primary }"
  >
    <slot />
  </v-container>
</template>
<script>
  export default {
    name: 'HeaderTop',
    props: {
      primary: Boolean,
      customRightContent: Boolean,
    },
    data () {
      return {
        colorActive: this.primary ? '#fff' : '#0094de',
      }
    },
  }
</script>
<style scoped>
  .custom-top-margin {
    margin-top: 150px;
  }

  @media screen and (max-width: 992px) {
    .custom-top-margin {
      margin-top: 10px;
    }

    .layout-general-private {    margin-top: 0px !important;}

  }

  @media screen and (max-width: 600px) {
    .custom-top-margin {margin-top: 10px;}
    .layout-general-private {margin-top: -20px !important;}
    div.container.pt-0.px-6.mt-n16.container--fluid { background-color: #000000 !important; padding-top: 0px !important; padding-right: 0px !important;  padding-left: 0px !important; margin-top: 10px !important;}

    div.container .pt-0 .px-6 .mt-n16 .container--fluid { background-color: #000000 !important; padding-top: 0px !important; padding-right: 0px !important;  padding-left: 0px !important; margin-top: 10px !important;}


}
</style>
