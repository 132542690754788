import { Service } from './service'

const PATH = 'user'

class UserService extends Service {
  getAvailableFields (data = {}) {
    return this.post(PATH + '/getAvailableFields', data)
  }

  getAvailableFieldsByType () {
    return this.post(PATH + '/available-fields-by-type')
  }

  getAvailableFieldsAdvancedByType (data = {}) {
    return this.post(PATH + '/available-fields-advanced-by-type', data)
  }

  getAvailableFieldsForGroupsAdvancedByType (data = {}) {
    return this.post(PATH + '/available-fields-for-groups-advanced-by-type', data)
  }

  getSectionsBlocked (params = {}) {
    return this.get(PATH + '/sections-blocked', params)
  }

  resetPassword (params = {}) {
    return this.post('password/reset', params)
  }

  sendOpinion (params) {
    return this.post(PATH + '/rate-app', params)
  }

  setShowRatingByUser (params) {
    return this.post(PATH + '/show-rating', params)
  }

  setShowAiProfileByUser (params) {
    return this.post(PATH + '/show-ai-profile', params)
  }

  setShowBillingProfileByUser (params) {
    return this.post(PATH + '/show-billing-profile', params)
  }

  getUserStatus () {
    return this.get(PATH + '/user-status')
  }

  getMailingFooterLegalText () {
    return this.get(PATH + '/mailing-footer-legal-text')
  }
}

export default new UserService()
