export default {
  name: 'SupportCard',
  components: {},
  props: {
    profile: {
      type: Object,
      require: true,
    },
  },
  data: function () {
    return {
      card: {
        name: null,
        image: null,
      },
    }
  },
  watch: {
  },

  computed: {
    hasUserManager () {
      return this.profile?.user?.user_manager?.name
    },
  },

  mounted () {

  },
  created () {

  },
  methods: {
  },
}
