import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import AiProfile from '@/components/AiProfile/AiProfile.vue'
import ProfileMenu from '@/views/Pages/Users/Common/ProfileMenu/ProfileMenu.vue'
import SupportCard from '@/views/Pages/Users/Common/SupportCard/SupportCard.vue'

export default {
  name: 'BillingProfile',
  components: {
    HeaderTopDashboard,
    AiProfile,
    ProfileMenu,
    SupportCard,
  },
  data () {
    return {
      aiProfileStatus: {
        edited: false,
      },
    }
  },
  methods: { },
  created () { },
}
